import {authHeader, instanceAxios} from "../config/axios.config";
import {REACT_APP_AUTHORIZATION_DEV} from "../config/env.config";

export const touchClick = async (count) => {
  try {

    const response = await instanceAxios.post(
      `/api/click/apply`,
      {
        count: count,
        web_app_data: REACT_APP_AUTHORIZATION_DEV,
      },
      { headers: authHeader() }
    );

    return  response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getLeagueStat = async () => {
  try {
      const response = await instanceAxios.get(`/api/stat`, {
          headers: authHeader()
      });
      return response.data; 
  } catch (error) {
      console.error('Ошибка при получении статистики:', error);
      throw error;
  }
};


export const getLeaguePlayers = async (leagueNumber) => {
  try {
      const response = await instanceAxios.get(`/api/league/${leagueNumber}`, {
          headers: authHeader()
      });
      return response.data; 
  } catch (error) {
      console.error('Ошибка при получении игроков лиги:', error);
      throw error;
  }
};

export const getReferrals = async () => {
  try {
      const response = await instanceAxios.get(`/api/referrals`, {
          headers: authHeader()
      });
      return response.data;
  } catch (error) {
      console.error(error);
  }
}

export const getKings = async () => {
  try {
      const response = await instanceAxios.get(`/api/king`, {
          headers: authHeader()
      });
      return response.data;
  } catch (error) {
      console.error(error);
  }
}

export const boosterApply = async (type) => {
  try {
      const response = await instanceAxios.post(`/api/booster/${type}/apply`, {},{
          headers: authHeader()
      });
      return response.data;
  } catch (error) {
      console.error(error);
  }
}