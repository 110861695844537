import axios from "axios";

export const instanceAxios = axios.create({
    withCredentials: true,
    headers: {
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
});

export function authHeader() {
    const token = localStorage.getItem('token');

    if (token) {
        return {
            Authorization: 'Bearer ' + token
        };
    } else {
        return {};
    }
}