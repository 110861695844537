import React from 'react';
import "./cubelight.css";

function CubeLight({ fill, filter, opacity }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="430" height="370" viewBox="0 0 430 370" fill="none" className='cube-light' pointerEvents="none">
      <defs>
        {filter && <filter id="custom-filter" x="-15" y="0" width="458" height="370" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_115_597" />
        </filter>}
        {fill && <linearGradient id="custom-fill" x1="218.448" y1="-12.4322" x2="217.519" y2="327.513" gradientUnits="userSpaceOnUse">
          <stop stopColor="#040918" stopOpacity="0" />
          <stop offset="0.60247" stopColor="#1C0A52" stopOpacity="0.16" />
          <stop offset="0.871225" stopColor="#FF5823" stopOpacity="0.39" />
          <stop offset="1" stopColor="#FF8D23" stopOpacity="0.78" />
        </linearGradient>}
      </defs>
      <g filter={filter ? "url(#custom-filter)" : "url(#filter0_f_115_597)"}>
        <path d="M10 25H418L302.966 345H132.674L10 25Z" fill={fill ? "url(#custom-fill)" : "url(#paint0_linear_115_597)"} opacity={opacity || "0"} />
      </g>
    </svg>
  );
}

export default CubeLight;