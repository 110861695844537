import React, { createContext, useState, useEffect, useContext } from 'react';
import {authHeader, instanceAxios} from "../config/axios.config";
import {REACT_APP_AUTHORIZATION_DEV} from "../config/env.config";
import {getLeagueStat} from "./apiRequests";

const UserContext = createContext();


export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState({ token: null }); // Добавляем токен в объект userData
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instanceAxios.post(`/api/auth/login`, { web_app_data: REACT_APP_AUTHORIZATION_DEV });
        const token = response.data.access_token;

        if (token) {
          localStorage.setItem('token', token);
          const profileResponse = await instanceAxios.get(`/api/profile`, { headers: authHeader() });
          const stat = await getLeagueStat();
          const profile = { ...profileResponse.data, token, stat };
          setUserData(profile);
        }
      } catch (error) {
        console.error("Ошибка авторизации:", error);
      }
    };

    fetchData();
  }, []);

  return <UserContext.Provider value={userData}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);
