import React from "react";
import {formatNumber, getTitleLeague} from "../../utils/utils";

const Player = ({player}) => {

    return (
        <div
            className="invite-button"
        >
            <div className="row">

                <div className="item__user-template-league">
                    <p className="item__user-short-league">
                        {player.user.first_name.slice(0, 2).toUpperCase()}
                    </p>
                    <img
                        src={`https://t.me/i/userpic/320/${player.user.username ?? ""}.jpg`}
                        alt="avatar"
                        className="item__user-avatar-league"
                    />
                </div>
                <div className="invite-info-text">
                    <div className="one-time-bonus">{player.user.first_name}</div>
                    <div className="invite-two-row">
                        <div className="row">
                            <img
                                src={`assets/img/league/diamonds/${player.league_id}.png`}
                                alt=""
                                width="22px"
                                className="league-mini-icon"
                            ></img>
                            <span className="small-text-button">{getTitleLeague(player.league_id)}</span>
                        </div>
                        <div className="row">
                            <img
                                src="/assets/img/coin.png"
                                alt=""
                                width="18px"
                                className="invite-row-coin"
                            ></img>
                            <span className="small-text-button">{formatNumber(player.balance_coins)}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row width-100 justify-end">
                <div className="coin-bonus-container">
                    <img
                        src="/assets/img/coin.png"
                        alt=""
                        width="26px"
                        className="middle-coin"
                    ></img>
                    <span className="invite-bonus-coin">+{player.league_id > 1 ? '2K' : '0'} </span>
                </div>
                {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M8.99998 19.7498C9.19198 19.7498 9.38401 19.6768 9.53001 19.5298L16.53 12.5298C16.823 12.2368 16.823 11.7618 16.53 11.4688L9.53001 4.46877C9.23701 4.17577 8.76197 4.17577 8.46897 4.46877C8.17597 4.76177 8.17597 5.23681 8.46897 5.52981L14.9389 11.9998L8.46897 18.4698C8.17597 18.7628 8.17597 19.2378 8.46897 19.5308C8.61597 19.6768 8.80798 19.7498 8.99998 19.7498Z"
                fill="white"
              />
            </svg> */}
            </div>
        </div>
    );
};

const PlayerListTeam = ({list}) => {

        return (
            <div className="friends-container">

                {list && Object.keys(list).length > 0 ? list.map((player, index) => (
                    <Player key={index} player={player}/>

                )) : (
                    <div
                        className="invite-button text-center"
                    >
                        Friends list is empty
                    </div>
                )}
            </div>


        )
            ;
    }
;

export default PlayerListTeam;
