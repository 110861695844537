import React from "react";
import {formatNumber} from "../../../utils/utils";

const Player = ({ player, index }) => {


  return (
    <div className="player-info-league">
      <div className="main-info-list-league">

        <span className="list-number-league">{index + 1}</span>
        
        <div className="item__user-template-league">
          <p className="item__user-short-league">
            {player.user.first_name.slice(0, 2).toUpperCase()}
          </p>
          <img
            src={`https://t.me/i/userpic/320/${player.user.username ?? ""}.jpg`}
            alt="avatar"
            className="item__user-avatar-league"
          />
        </div>

        <div className="player-info-text-league">
          <div className="player-name-league">{player.user.first_name}</div>
          <div className="row-player-score-league">
            <img
              src="/assets/img/coin.png"
              alt=""
              width="18px"
              className="row-coin-league"
            />
            <span>{formatNumber(player.total_coins)}</span>
          </div>
        </div>

      </div>
    </div>
  );
};

const PlayerList = ({ leaguePlayers }) => {
  return (
    <div className="player-list-league">
      {leaguePlayers && leaguePlayers.map((player, index) => (
        <Player key={index} player={player} index={index} />
      ))}
    </div>
  );
};

export default PlayerList;
