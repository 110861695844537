import {REACT_APP_BOT_NAME} from "../config/env.config";

export function generateSearchString(params) {
    const searchParams = new URLSearchParams();

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            searchParams.append(key, params[key]);
        }
    }

    return searchParams.toString();
}

export function generateRefLink(id = '') {
    const url = `t.me/${REACT_APP_BOT_NAME}/click?startapp=ref_${id}`
    const text = 'Join me earn a million by clicking'
    return `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;
}