import React from "react";

const Diamond1 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="341"
    height="89"
    viewBox="0 0 341 89"
    fill="none"
    className="diamond-blink1-league"
  >
    <path
      style={{ mixBlendMode: "screen" }}
      d="M199.398 88.1793C309.522 88.1793 398.795 68.5951 398.795 44.4368C398.795 20.2785 309.522 0.694336 199.398 0.694336C89.2733 0.694336 0 20.2785 0 44.4368C0 68.5951 89.2733 88.1793 199.398 88.1793Z"
      fill="url(#paint0_radial_115_7237)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_115_7237"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(199.391 44.2338) rotate(-180) scale(191.995 42.3549)"
      >
        <stop stopColor="#FFF8F1" />
        <stop offset="0.06" stopColor="#DCD5CF" stopOpacity="0.86" />
        <stop offset="0.16" stopColor="#A8A49F" stopOpacity="0.66" />
        <stop offset="0.26" stopColor="#7B7874" stopOpacity="0.49" />
        <stop offset="0.37" stopColor="#555350" stopOpacity="0.34" />
        <stop offset="0.48" stopColor="#363533" stopOpacity="0.21" />
        <stop offset="0.59" stopColor="#1E1D1C" stopOpacity="0.12" />
        <stop offset="0.71" stopColor="#0D0C0C" stopOpacity="0.05" />
        <stop offset="0.84" stopColor="#030303" stopOpacity="0.01" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

const Diamond2 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="88"
    height="111"
    viewBox="0 0 88 111"
    fill="none"
    className="diamond-blink2-league"
  >
    <path
      style={{ mixBlendMode: "screen" }}
      d="M51.9002 68.4362C75.5381 38.9332 91.3398 9.19229 87.1941 2.00808C83.0485 -5.17612 60.5255 12.9169 36.8875 42.4199C13.2496 71.9229 -2.55209 101.664 1.59355 108.848C5.73918 116.032 28.2622 97.9392 51.9002 68.4362Z"
      fill="url(#paint0_radial_115_7239)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_115_7239"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(46.3393 58.8426) rotate(-51.2981) scale(65.9108 13.6904)"
      >
        <stop stopColor="#FFF8F1" />
        <stop offset="0.06" stopColor="#DCD5CF" stopOpacity="0.86" />
        <stop offset="0.16" stopColor="#A8A49F" stopOpacity="0.66" />
        <stop offset="0.26" stopColor="#7B7874" stopOpacity="0.49" />
        <stop offset="0.37" stopColor="#555350" stopOpacity="0.34" />
        <stop offset="0.48" stopColor="#363533" stopOpacity="0.21" />
        <stop offset="0.59" stopColor="#1E1D1C" stopOpacity="0.12" />
        <stop offset="0.71" stopColor="#0D0C0C" stopOpacity="0.05" />
        <stop offset="0.84" stopColor="#030303" stopOpacity="0.01" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

const Diamond3 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="237"
    height="57"
    viewBox="0 0 237 57"
    fill="none"
    className="diamond-blink3-league"
  >
    <path
      style={{ mixBlendMode: "screen" }}
      d="M236.115 51.2579C236.953 41.9161 184.93 24.1253 119.92 11.5209C54.9091 -1.08343 1.5288 -3.72828 0.69142 5.61351C-0.14596 14.9553 51.8767 32.7462 116.887 45.3505C181.898 57.9548 235.278 60.5997 236.115 51.2579Z"
      fill="url(#paint0_radial_115_7238)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_115_7238"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(128.715 33.9472) rotate(10.9725) scale(115.724 16.5305)"
      >
        <stop stopColor="#FFF8F1" />
        <stop offset="0.06" stopColor="#DCD5CF" stopOpacity="0.86" />
        <stop offset="0.16" stopColor="#A8A49F" stopOpacity="0.66" />
        <stop offset="0.26" stopColor="#7B7874" stopOpacity="0.49" />
        <stop offset="0.37" stopColor="#555350" stopOpacity="0.34" />
        <stop offset="0.48" stopColor="#363533" stopOpacity="0.21" />
        <stop offset="0.59" stopColor="#1E1D1C" stopOpacity="0.12" />
        <stop offset="0.71" stopColor="#0D0C0C" stopOpacity="0.05" />
        <stop offset="0.84" stopColor="#030303" stopOpacity="0.01" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export { Diamond1, Diamond2, Diamond3 };
