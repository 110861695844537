import React, { useEffect } from "react";
import {touchClick} from "../../../api/apiRequests";

const Footer = ({ energy, setEnergy, vibrate, user, handleTouchClick }) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (energy < user.limit_coins) {
        setEnergy((prevEnergy) => prevEnergy + 1); // Увеличиваем значение энергии на 1
      }
    }, 3600);

    // Очищаем интервал при размонтировании компонента
    return () => clearInterval(intervalId);
  }, [energy, setEnergy]); // Зависимости: energy и setEnergy

  const openEarnPage = () => {
    handleTouchClick()
    setTimeout(() => {
      window.location.href = "earn";
    }, 500); 
  };

  const openBoostsPage = () => {
    handleTouchClick()
    setTimeout(() => {
      window.location.href = "boosts";
    }, 500); 
  };

  const openFriendPage = () => {
    handleTouchClick()
    setTimeout(() => {
      window.location.href = "team";
    }, 500);
  };

  return (
    <div className="footer">
      <div className="energy-section">
        <div className="energy-div">
          <div className="energy-score-container">
            <img src="assets/img/fire-icon.png" alt="" className="fire-icon" />
            <div className="energy-container">
              <div className="energy">{energy}</div>
              <div className="energy-storage">/ {user.limit_coins}</div>
            </div>
          </div>
        </div>
        <div className="progress-bar-full">
          <div
            className="progress-bar"
            style={{ width: `${(energy/user.limit_coins) * 100}%` }}
          ></div>{" "}
          {/* Пример стилизации прогресс-бара на основе значения энергии */}
        </div>
        <div className="progress-bar-section">
          <div className="progress-empty"></div>
        </div>
      </div>
      <div className="mini-buttons-container">
        <div
          className="mini-button"
          onClick={openEarnPage}
          onTouchStart={vibrate}
        >
          <img src="assets/img/coin.png" alt="" className="mini-button-icons" />
          <span>Earn</span>
        </div>

        <div
          className="mini-button"
          onClick={openBoostsPage}
          onTouchStart={vibrate}
        >
          <img
            src="assets/img/button-icons/rocket.png"
            alt=""
            className="mini-button-icons"
          />
          <span>Boost</span>
        </div>

        <div
          className="mini-button"
          onClick={openFriendPage}
          onTouchStart={vibrate}
        >
          <img
            src="assets/img/hand-ok.png"
            alt=""
            className="mini-button-icons"
          />
          <span>Team</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
